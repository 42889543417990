<template>
  <!-- {{ UserInfo }} -->
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
  >
    <transition name="zoom">
      <Alert
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        type="Danger"
        classOkBtn="btn-danger"
        artIcon="deleteFill"
        :altMsgBox="$t('60')"
        :Helper="$t('345')"
        @confirm="fetchDeleteCampus($event)"
        :blnLoading="blnloadConfirmDelet"
        ref="AlertPopup"
        :showpass="true"
        :btnpop="true"
        classIcon="fill-danger"
        :errorMsg="$t(errorMsg)"
        :blnError="blnError"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        @click="blnloadConfirmDelet == false ? ConfirmDelete() : ''"
      ></div>
    </transition>
  </div>
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
  >
    <transition name="zoom">
      <Alert
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="$t('1287', { LONGINFAILNUM: intPinCount, LockContact: strTel })"
        :Helper="$t('345')"
        @confirm="reloadpage($event)"
        ref="AlertPopupp"
        :btnpop="true"
        classIcon="fill-warning"
        :Cancel="false"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
      ></div>
    </transition>
  </div>
  <!-- <div
    :blnalert="blnAltStatus || blnAltStatusunsucess || blnAltLoadingData"
    :class="
      blnAltStatus || blnAltStatusunsucess || blnAltLoadingData ? '' : 'd-none'
    "
  > -->

  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />

  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltStatusunsucess ? 'show' : ''"
  />

  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('172')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoadingData ? 'show' : ''"
  />

  <AltStatus
    :blnalert="blnnotdelete"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('428')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotdelete ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotedit"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('222')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotedit ? 'show' : ''"
  />
  <!-- </div> -->

  <Header
    icon="campusOutline"
    :headerName="$t('2')"
    :breadcrumbname="
      action == 'Insert' ? $t('20') : '' || action == 'Update' ? $t('378') : ''
    "
    :btnName="$t('20')"
    class="mb-3"
    @onInput="openform($event)"
    :breadcrumb="blnOpenform == true ? '' : 'd-none'"
    :helperHeader="blnOpenform == true ? $t('30') : ''"
    :hintHelperHeader="blnOpenform == true ? '' : 'd-none'"
    :rightHeader="blnOpenform == true ? 'd-none' : ''"
    :underline="blnOpenform == true ? 'openUnderline' : 'closeUnderline'"
    @showFilter="openFilter($event)"
    id="header"
    ListCampusClass="d-none"
    :Searchname="$t('14')"
    @onSearch="SearchCampus($event)"
    @onClean="clean()"
    :blnOpenform="blnOpenform"
    :showFilter="blnOpenFilter"
    :filterActive="{
      blnOpenform: blnOpenFilter,
      useFilter: activeFiltersSelected.length == 0 ? false : true,
      disableFilter: false,
    }"
    ref="header"
  />

  <div class="popup-container" v-show="blnOpenFilter == true">
    <transition name="zoom">
      <Filter
        v-show="blnOpenFilter == true"
        @appectFilter="filteredData($event)"
        :listDataFilter="listcampusFilter"
        @Cancel="blnOpenFilter = !blnOpenFilter"
        :blnOpenFilter="blnOpenFilter"
        :listActiveFiltersSelected="activeFiltersSelected"
        :blnHaveDecision="true"
        ref="filter"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnOpenFilter == true"
        @click="blnOpenFilter = false"
      ></div>
    </transition>
  </div>
  <div>
    <Table
      :progressScore="progress"
      :nametable="'2'"
      v-if="!blnOpenform"
      :ListDataTable="ListCampus"
      firstSort="intCpstatus"
      secondSort="strCampusNameEN"
      @edit="fetchGetCampusbyId($event)"
      @ConfigDisplay="reloadConfigDisplay()"
      @onSaveConfigDisplay="onSaveConfigDisplay($event)"
      ref="table"
    />
  </div>
  <div
    class="d-flex mt-sm-3 ms-lg-9 ms-md-0 m-sm-0 justify-content-start align-items-start"
    v-if="blnOpenform == true"
    :class="
      device == 'mobile' || device == 'tablet'
        ? 'flex-wrap move-to-top-level'
        : ''
    "
  >
    <div
      class="rounded-2 shadow-sm bg-white me-lg-5 me-sm-0 layout"
      :class="device == 'mobile' ? 'mb-5 me-0 w-100' : 'form-lg-fix mb-4'"
    >
      <Form
        v-if="blnOpenform == true"
        :typeSchema="typeSchema"
        :data="formdata"
        @onInput="openform($event)"
        @Insert="fetchInsertCampus($event)"
        @Update="fetchUpdateCampus($event)"
        @delete="ConfirmDelete()"
        :action="action"
        :request="request"
        :inactive="formInactive"
        :msgalertDataDuplicate="msgalertDataDuplicate"
        ref="form"
      />
    </div>
    <Relate
      :class="device == 'mobile' || device == 'tablet' ? 'mt-0 mb-3 w-100' : ''"
      v-if="blnOpenform == true && listRelate.used == true"
      :listRelate="listRelate"
      style="width: 350px"
      class="layout"
    />
  </div>
</template>

<script>
import Relate from "@/components/cards/relate.vue";
import Filter from "@/components/filter/filter.vue";
import Header from "@/components/headerMenu/haeder.vue";
import Table from "@/components/table/table.vue";
import Form from "@/components/form/form.vue";
import URLAPI from "@/typescript/urlapi/url";
import APICampus from "@/typescript/urlapi/adminsystem/APICampus";
import { typeSchema, request } from "@/components/constants/schemaAddCampus";
import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
import AltStatus from "@/components/alert/alertStatus.vue";
import Alert from "@/components/alert/alertPopup.vue";
import AdsInventories from "@/typescript/inventory";
//import SortArray from "../typescript/sort/sorting";
import Enumerable from "linq";
import apicampus from "@/typescript/urlapi/adminsystem/APICampus";
import Login from "@/typescript/login";
import VueCookies from "vue-cookies";
import cookiesPath from "@/typescript/urlapi/cookiesPath";
// import NotFound from "../components/notFound/notFound.vue"
export default {
  components: {
    Header,
    Table,
    Form,
    Filter,
    AltStatus,
    Relate,
    Alert,
  },
  data() {
    return {
      blnloadError: false,
      blnloadConfirmDelet: false,
      blnPopupConfirmDelete: false,
      strSearch: "",
      msgalertDataDuplicate: [], //msg
      action: "Insert", //action of form
      strSMSGsuccess: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      blnAltLoadingData: false, //Alt Status logint
      blnnotedit: false,
      blnnotdelete: false,
      hover: false, //hover alt
      timerEnabled: false,
      timerCount: 0,
      timerCountHover: 0,
      progress: 0,
      strSearchField: [
        "strCampusNameEN",
        "strCampusNameTH",
        "strCampusInitialEN",
        "strCampusInitialTH",
      ],
      typeSchema: typeSchema,
      request: request,
      formdata: {},
      formdataDummy: {},
      campusId: null,
      blnOpenform: false,
      ListCampus: {},
      ListDummy: {},
      formInactive: [],
      blnOpenFilter: false,
      blnopenConfigDisplay: false,
      blnError: false,
      intPinCount: 1,
      strTel: "",
      listcampusFilter: [
        {
          list: [
            { name: "2" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 1, lang: "21" }, //main
                { id: 0, lang: "74" }, //branch
              ],
            },
            { state: "intCampusMain" },
          ],
        },
        {
          list: [
            { name: "19" },
            {
              data: [
                { id: null, lang: "79", intCampusMain: "" }, //all
                { id: 1, lang: "61", intCampusMain: "" }, //open
                { id: 0, lang: "62", intCampusMain: "" }, //close
                { id: 1, lang: "61", intCampusMain: 1 }, //open
              ],
            },
            { state: "intCpstatus" },
          ],
        },
      ],
      activeFiltersSelected: [],
      listRelate: [],
      updateAdsInventories: null,

      intWrongLogin: 0,
      errorMsg: "",
    };
  },
  updated() {},
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCountHover++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCountHover++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
          this.blnAltLoadingData = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    // timerCount: {
    //   handler(value) {
    //     if (value < 10 && this.timerEnabled && this.progress < 75) {
    //       setTimeout(() => {
    //         this.timerCount++;

    //         this.progress += 5 * this.timerCount;
    //       }, 200);
    //     } else {
    //       this.progress = 100;
    //     }
    //   },

    //   immediate: true, // This ensures the watcher is triggered upon creation
    // },
  },
  unmounted() {
    clearInterval(this.updateAdsInventories);
  },
  mounted() {
    // console.log(AdsInventories.userinfo);
    // ตรวจสอบการเลือก sidebar
    this.updateAdsInventories = setInterval(() => {
      if (AdsInventories.blnCloseform == false) {
        this.openform(false);
        AdsInventories.blnCloseform = true;
      }
    }, 500);
    this.blnOpenFilter = false;
    // setTimeout(() => {
    //   if (localStorage.getItem("LoginState") != "false") {
    //     // console.log("logined");
    //     this.$router.push("/campus");
    //   }
    // }, 1000);
  },
  //เรียกข้อมูล วิทยาเขต จากฐานข้อมูล ก่อนเปิดหน้าเว็บ
  beforeCreate() {},
  created() {
    this.processLoadingData();
  },

  methods: {
    // load config
    reloadConfigDisplay() {
      if (this.$refs.table != null) {
        this.$refs.table.setSelectedList(
          JSON.parse(localStorage.getItem("SelectedListCampus"))
        );
      }
    },
    //Filter รับค่า จากตัวกรองกับข้อมูลชุดเดิม
    filter(activeFilters, ListDummy) {
      this.ListCampus.data = ListDummy.slice();

      for (var field in activeFilters) {
        this.ListCampus.data = this.ListCampus.data.filter(
          (elem) => elem[activeFilters[field].state] == activeFilters[field].id
        );
      }
      if (this.$refs.table != null) {
        this.$refs.table.onClean();
        this.$refs.table.calculatePage(
          this.ListCampus.data.length,
          this.ListCampus.data
        );
      }
      this.$refs.table.SearchListDataTable(this.strSearch);
      this.blnOpenFilter = false;

      this.ListDummy.data = ListDummy;
    },
    // รับค่าจาก component filter
    filteredData(activeFilters) {
      this.activeFiltersSelected = activeFilters;
      this.filter(activeFilters, this.ListDummy.data);
    },
    // เก็บค่า config
    onSaveConfigDisplay(SelectedList) {
      localStorage.setItem("SelectedListCampus", JSON.stringify(SelectedList));
    },
    //เปิด/ปิด ฟอร์ม
    async openform(state) {
      try {
        this.activeFiltersSelected = [];
        // console.log(state);
        //ปิดฟอร์ม
        if (state == false) {
          await this.processLoadingData();
          if (this.$refs.header != null) {
            this.$refs.header.onClear();
          }
        }
        //เปิดฟอร์ม
        else if (state == true) {
          this.formInactive = [];
          //กำหนด action insert
          this.action = "Insert";

          // let inactive = {
          //   name: "intCampusMain",
          //   inactive: true,
          //   tooltip: true,
          // };

          // var tooltip = {
          //   name: "intCampusMain",
          // };

          // this.formInactive.push(inactive);
          var activeDelete = {
            name: "blnDeleteinForm",
            inactive: true,
            tooltip: false,
          };
          let activeConfirm = {
            name: "intCampusMain",
            blnConfirm: true,
          };
          this.formInactive.push(activeConfirm);
          this.formInactive.push(activeDelete);
          //เคลียข้อมูลในฟอร์ม
          localStorage.removeItem("strCampusNameEN");
          localStorage.removeItem("strCampusNameTH");

          this.formdata = {};
          this.formdata = {
            strCampusWebsite: "https://",
            strCampusFacebook: "https://www.facebook.com/",
            strCountrycode: "66",
          };
        }
        this.blnOpenform = state;
      } catch (error) {
        console.log(error);
      }
    },
    // Popup delete
    ConfirmDelete() {
      // console.log("click button delete");
      this.blnError = false;
      if (this.$refs.AlertPopup != undefined) {
        this.$refs.AlertPopup.blnShowMSG = false;
      }
      this.$refs.AlertPopup.password = null;
      this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
    },

    // skeleton loading ข้อมูล campus
    async processLoadingData() {
      this.listRelate = [];
      this.progress = 0;
      this.ListDummy = [];
      this.ListCampus = [];
      this.timerCountHover = 0;
      this.timerEnabled = true;

      this.formdata = {};
      this.formdataDummy = {};
      this.ListCampus = {};
      this.ListDummy = {};
      // load ข้อมูล campus
      await this.fetchListCampus().then(() => {
        this.processData().then(() => {
          setTimeout(() => {
            if (this.blnloadError == false) {
              this.progress = 100;
            }
            // if (this.progress > 70) {
            //   // console.log("100");
            //   this.progress = 100;
            // }
          }, 1000);
        });
      });

      //this.progress = 100;
    },

    //บันทึกข้อมูลของ Campus ไปยัง Database
    async fetchInsertCampus(data) {
      this.msgalertDataDuplicate = [];

      // console.log(data);
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        //console.log(key);

        if (
          key !== "strPicpath" &&
          key !== "confirmintCampusMain" &&
          key !== "confirmintSubdistrict" &&
          key !== "confirmintCpstatus" &&
          key !== "strCountrycode" &&
          key !== "strCampusTelTH" &&
          key !== "strCampusFaxTH" &&
          key !== "strCampusTelEN" &&
          key !== "strCampusFaxEN" &&
          key !== "intSubdistrict"
        ) {
          // console.log(`${key}: ${data[key]}`);
          formData.append(key, data[key]);
        } else if (key === "strPicpath") {
          formData.append(key, data[key]);
        } else if (key === "strCampusTelTH") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strCampusTelTH: data[key],
          };
          formData.append(key, JSON.stringify(list));
        } else if (key === "strCampusFaxTH") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strCampusFaxTH: data[key],
          };
          formData.append(key, JSON.stringify(list));
        } else if (key === "strCampusTelEN") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strCampusTelEN: data[key],
          };
          formData.append(key, JSON.stringify(list));
        } else if (key === "strCampusFaxEN") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strCampusFaxEN: data[key],
          };
          // console.log(list.toString());
          formData.append(key, JSON.stringify(list));
        } else if (key === "intSubdistrict") {
          // console.log(key);
          // console.log(data[key].id == undefined);
          formData.append(
            "intProvince",
            data[key].id != undefined
              ? data[key].id.intProvinceID
              : data["strSubdistrict"].id.intProvinceID
          );
          formData.append(
            "intDistrict",
            data[key].id != undefined
              ? data[key].id.intDistrictID
              : data["strSubdistrict"].id.intDistrictID
          );
          formData.append(
            "intSubdistrict",
            data[key].id != undefined
              ? data[key].id.intSubdistrictID
              : data["strSubdistrict"].id.intSubdistrictID
          );
          formData.append(
            "strPostcode",
            data[key].id != undefined
              ? data[key].id.strPostcode.toString()
              : data["strSubdistrict"].id.strPostcode.toString()
          );
        }
      });
      // console.log(formData);
      // Object.entries(data).forEach(([key, value]) => {
      //   if (key !== "strCampusLogo") {
      //     formData.append(key, value);
      //   } else if (key === "strCampusLogo") {
      //     console.log(value);
      //     formData.append(key, value);
      //   }
      // });
      // for (let [key, value] of formData) {
      //   console.log(`${key}: ${value}`);
      // }
      try {
        // console.log(
        //   URLAPI +
        //     APICampus.insertCampus +
        //     AdsInventories.userinfo.intMemberID +
        //     (data.confirmintCampusMain !== undefined
        //       ? "&Confirm=" + data.confirmintCampusMain
        //       : "&Confirm=" + false)
        // );

        var res = await axios.post(
          URLAPI +
            APICampus.insertCampus +
            AdsInventories.userinfo.intMemberID +
            (data.confirmintCampusMain !== undefined
              ? "&Confirm=" + data.confirmintCampusMain
              : '"&Confirm=" + false'),
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        // console.log(res);
        if (res.data.inserted == true) {
          this.blnOpenform = false;
          this.blnAltStatus = true;
          this.strSMSGsuccess = "26";
          setTimeout(() => {
            this.blnAltStatus = false;
          }, 2000);
          this.progress = 0;
          //delay 1 sec
          setTimeout(() => {
            //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
            this.openform(false);
          }, 1000);
        }
        if (res.data.inserted == false) {
          this.msgalertDataDuplicate = [];
          if (res.data.campusnameEN == true) {
            let Message = { name: "strCampusNameEN", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }
          if (res.data.campusnameTH == true) {
            let Message = { name: "strCampusNameTH", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }
          // console.log(this.msgalertDataDuplicate);
          this.$refs.form.detectalertDataDuplicate(this.msgalertDataDuplicate);
        }
        if (res.data.code === 500) {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;

          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
        }
      } catch (error) {
        this.blnAltStatusunsucess = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;

        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 5000);
        throw new Error(error);
      }
      return true;
    },
    //จัดการข้อมูลแล้วไปแสดงผลในตาราง
    async processData() {
      setTimeout(() => {
        this.ListCampus.data = Enumerable.from(this.ListCampus.data)
          .orderByDescending((r) => r.blnCampusStatus)
          .thenByDescending((r) => r.intCampusMain)
          .thenBy((r) => r.strCampusNameEN.toString().toLowerCase())
          .toArray();
      }, 100);
      setTimeout(() => {
        if (
          localStorage.getItem("SelectedListCampus") == null ||
          localStorage.getItem("SelectedListCampus") == "undefined"
        ) {
          localStorage.setItem(
            "SelectedListCampus",
            JSON.stringify(this.ListCampus.defaultField)
          );
        }
      }, 100);
      //delay 1 sec
      setTimeout(() => {
        if (this.$refs.table != null) {
          this.$refs.table.onClean();
          this.$refs.table.calculatePage(
            this.ListCampus.total,
            this.ListCampus.data
          );
          // ค่า config ไม่เป็น undefined
          if (localStorage.getItem("SelectedListCampus") != "undefined") {
            this.$refs.table.setSelectedList(
              JSON.parse(localStorage.getItem("SelectedListCampus"))
            );
          } else {
            // ค่า config เป็น undefined ให้ใช้ config จาก defaultfield
            // และ set ค่า config นั้นไว้
            this.$refs.table.setSelectedList(this.ListCampus.defaultField);
            localStorage.setItem(
              "SelectedListCampus",
              JSON.stringify(this.ListCampus.defaultField)
            );
          }
        }
      }, 100);
      if (this.$refs.filter != null) {
        this.$refs.filter.Clear();
      }

      //this.timerEnabled = true;
      return true;
    },
    // อัพเดทข้อมูล campus
    async fetchUpdateCampus(data) {
      this.msgalertDataDuplicate = [];
      // console.log(data);
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        //console.log(key);
        // console.log(`${key}: ${data[key]}`);
        if (
          key !== "strPicpath" &&
          key !== "confirmintCampusMain" &&
          key !== "confirmintCpstatus" &&
          key !== "strCountrycode" &&
          key !== "strCampusTelTH" &&
          key !== "strCampusFaxTH" &&
          key !== "strCampusTelEN" &&
          key !== "strCampusFaxEN" &&
          key !== "intSubdistrict"
        ) {
          // console.log(`${key}: ${data[key]}`);
          formData.append(key, data[key]);
        } else if (key === "strPicpath") {
          formData.append(key, data[key]);
        } else if (key === "strCampusTelTH") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strCampusTelTH: data[key],
          };
          formData.append(key, JSON.stringify(list));
        } else if (key === "strCampusFaxTH") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strCampusFaxTH: data[key],
          };
          formData.append(key, JSON.stringify(list));
        } else if (key === "strCampusTelEN") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strCampusTelEN: data[key],
          };
          formData.append(key, JSON.stringify(list));
        } else if (key === "strCampusFaxEN") {
          // formData.append(key, data[key]);
          let list = {
            strCountryCode: data["strCountrycode"],
            strCampusFaxEN: data[key],
          };
          // console.log(list.toString());
          formData.append(key, JSON.stringify(list));
        } else if (key === "intSubdistrict") {
          // console.log(key);
          // console.log(data[key].id == undefined);
          formData.append(
            "intProvince",
            data[key].id != undefined
              ? data[key].id.intProvinceID
              : data["strSubdistrict"].id.intProvinceID
          );
          formData.append(
            "intDistrict",
            data[key].id != undefined
              ? data[key].id.intDistrictID
              : data["strSubdistrict"].id.intDistrictID
          );
          formData.append(
            "intSubdistrict",
            data[key].id != undefined
              ? data[key].id.intSubdistrictID
              : data["strSubdistrict"].id.intSubdistrictID
          );
          formData.append(
            "strPostcode",
            data[key].id != undefined
              ? data[key].id.strPostcode.toString()
              : data["strSubdistrict"].id.strPostcode.toString()
          );
        }
      });
      // for (let [key, value] of formData) {
      //   console.log(`${key}: ${value}`);
      // }
      let isEqual = JSON.stringify(this.formdataDummy) === JSON.stringify(data);
      if (isEqual == true) {
        await axios
          .get(urlapi + apicampus.getCampusByID + this.campusId)
          .then((res) => {
            if (
              res.data.message ==
              "Cannot edit ,Please check mapping Id in interface"
            ) {
              this.blnOpenform = false;
              this.blnnotedit = true;
              this.progress = 0;
              setTimeout(() => {
                this.blnnotedit = false;
                this.processLoadingData();
              }, 5000);
            } else {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              this.progress = 0;
              setTimeout(() => {
                this.processLoadingData();
                this.blnAltStatus = false;
              }, 2000);
            }
          });
      } else {
        // console.log(
        //   urlapi +
        //     APICampus.updateCampus +
        //     this.campusId +
        //     "?MemberID=" +
        //     AdsInventories.userinfo.intMemberID +
        //     (data.confirmintCampusMain != undefined
        //       ? "&Confirm=" + data.confirmintCampusMain
        //       : "&Confirm=" + false)
        // );
        await axios
          .put(
            urlapi +
              APICampus.updateCampus +
              this.campusId +
              "?MemberID=" +
              AdsInventories.userinfo.intMemberID +
              (data.confirmintCampusMain != undefined
                ? "&Confirm=" + data.confirmintCampusMain
                : "&Confirm=" + false),
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          )
          .then((res) => {
            // console.log(res);
            if (res.data.edited == true) {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              setTimeout(() => {
                this.blnAltStatus = false;
              }, 2000);
              this.progress = 0;
              //delay 1 sec
              setTimeout(() => {
                //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
                this.openform(false);
              }, 1000);
            }
            if (res.data.edited == false) {
              if (res.data.campusnameEN == true) {
                let Message = { name: "strCampusNameEN", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
                // console.log("ชื่ออังกฤษซ้ำ");
              }
              if (res.data.campusnameTH == true) {
                let Message = { name: "strCampusNameTH", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
                // console.log("ชื่อไทยซ้ำ");
              }
              //ถ้าไม่สามารถ edit ได้ แล้วชื่อไม่ซ้ำ
              if (
                res.data.message ==
                "Cannot edit ,Please check mapping Id in interface"
              ) {
                // console.log("ไม่ซ้ำ");
                this.blnOpenform = false;
                this.blnnotedit = true;
                setTimeout(() => {
                  this.blnnotedit = false;
                  this.processLoadingData();
                }, 5000);
              }

              this.$refs.form.detectalertDataDuplicate(
                this.msgalertDataDuplicate
              );
            }
            if (res.data.code === 500) {
              this.blnAltStatusunsucess = true;
              this.timerCountHover = 0;
              this.timerEnabled = true;
              this.hover = false;
              setTimeout(() => {
                this.blnAltStatusunsucess = false;
              }, 5000);
            }
          })
          .catch((e) => {
            this.blnAltStatusunsucess = true;

            this.timerCountHover = 0;
            this.timerEnabled = true;
            this.hover = false;
            console.log(e);

            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          });
      }
    },

    // ดึงข้อมูลจาก CampusID
    async fetchGetCampusbyId(CampusId) {
      try {
        this.campusId = CampusId;
        // this.formdataDummy = { ...res.data.data };
        var resRelate = await axios.get(
          urlapi + APICampus.checkusedstatus + CampusId
        );
        // console.log(resRelate);
        var res = await axios.get(urlapi + APICampus.getCampusByID + CampusId);

        this.action = "Update";
        // console.log(res.data);
        //ถ้าลบไปแล้ว แต่ยังอยู่หน้า view แล้วกดEdit
        if (
          res.data.message == "Not found ,Please check mapping Id in interface"
        ) {
          this.blnnotedit = true;
          setTimeout(() => {
            this.blnnotedit = false;
            this.processLoadingData();
          }, 4000);
        }

        localStorage.setItem("strCampusNameEN", res.data.data.strCampusNameEN);
        localStorage.setItem("strCampusNameTH", res.data.data.strCampusNameTH);

        if (res.data.data.intCampusMain == 1) {
          this.formInactive = [];
          let inactive = {
            name: "intCampusMain",
            inactive: true,
            tooltip: true,
            enable: true, //first toggle = open
          };
          // var tooltip = {
          //   name: "intCampusMain",
          // };
          this.formInactive.push(inactive);
          let inactivestauts = {
            name: "intCpstatus",
            inactive: true,
            tooltip: false,
          };
          let deleteinactive = {
            name: "blnDeleteinForm",
            inactive: true,
            tooltip: true,
          };
          this.formInactive.push(deleteinactive);
          this.formInactive.push(inactivestauts);
          // this.formInactive.push(tooltip);
        } else if (res.data.data.intCampusMain != 1) {
          this.formInactive = [];
          let inactive = {
            name: "intCampusMain",
            inactive: false,
            tooltip: false,
          };

          // var tooltip = {
          //   name: "intCampusMain",
          // };
          let activeConfirm = {
            name: "intCampusMain",
            blnConfirm: true,
          };

          this.formInactive.push(activeConfirm);
          this.formInactive.push(inactive);
          // this.formInactive.push(tooltip);
        }

        this.formdata = res.data.data;

        for (var d in this.formdata) {
          if (d == "strCampusTelTH") {
            let tel = JSON.parse(this.formdata[d]);
            // console.log(tel);
            this.formdata[d] = tel.strCampusTelTH;
            this.formdata["strCountrycode"] = tel.strCountryCode;
          }
          if (d == "strCampusFaxTH") {
            if (this.formdata[d] !== "") {
              let tel = JSON.parse(this.formdata[d]);
              // console.log(tel);
              this.formdata[d] = tel.strCampusFaxTH;
              this.formdata["strCountrycode"] = tel.strCountryCode;
            }
          }
          if (d == "strCampusTelEN") {
            let tel = JSON.parse(this.formdata[d]);
            // console.log(tel);
            this.formdata[d] = tel.strCampusTelEN;
            this.formdata["strCountrycode"] = tel.strCountryCode;
          }
          if (d == "strCampusFaxEN") {
            if (this.formdata[d] !== "") {
              let tel = JSON.parse(this.formdata[d]);
              // console.log(tel);
              this.formdata[d] = tel.strCampusFaxEN;
              this.formdata["strCountrycode"] = tel.strCountryCode;
            }
          }
        }

        this.listRelate = { ...resRelate.data };

        var activeConfirm = {
          name: "intCpstatus",
          blnConfirm: true,
        };
        this.formInactive.push(activeConfirm);
        if (this.listRelate.used == true) {
          var inactiveDelete = {
            name: "blnDeleteinForm",
            inactive: true,
            tooltip: false,
          };
          this.formInactive.push(inactiveDelete);
        }
        // console.log(this.formInactive);
        this.blnOpenform = true;
      } catch (error) {
        console.log(error);
      }
    },

    //เรียกข้อมูลของ Campus ทั้งหมด
    async fetchListCampus() {
      this.timerCount = 0;
      this.timerEnabled = true;
      this.blnloadError = true;
      await axios
        .get(URLAPI + APICampus.getCampusList)
        .then((res) => {
          this.ListDummy = res.data;
          this.ListCampus = res.data;
          //เช็คข้อมูลวิทยาเขต ว่าลบได้หรือไม่
          this.blnloadError = false;
        })
        .catch((e) => {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          this.progress = 0;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
          console.log(e);
        });
      return true;
    },

    // reloadpage ไปหน้า logout
    reloadpage() {
      Login.logout();
      //ไปยังหน้าแรก
      // setTimeout(() => {
      //   this.$router.push("/login");
      //   this.$router.go();
      // }, 100);
    },
    //ลบข้อมูลด้วย ID
    async fetchDeleteCampus(state) {
      try {
        this.blnloadConfirmDelet = true;
        if (state === false) {
          // console.log(state);
          this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
          this.blnloadConfirmDelet = false;
        } else {
          if (
            this.$refs.AlertPopup.password != null &&
            this.$refs.AlertPopup.password != ""
          ) {
            var strUsername = AdsInventories.userinfo.strUsername.split("@");
            var reslogin = await Login.login(
              strUsername[0],
              state,
              axios,
              urlapi
            );
            var login = {
              username: strUsername[0],
              password: state,
            };

            // console.log(reslogin);
            if (reslogin.logined == true) {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              var res = await axios.put(
                urlapi + APICampus.deleteCampus + this.campusId,
                login
              );
              // console.log(res.data);
              if (res.data.candelete == true) {
                this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
                this.blnError = false;
                this.blnPopupConfirmDelete = false;
                this.strSMSGsuccess = "28";
                this.blnOpenform = false;
                this.blnAltStatus = true;
                this.progress = 0;
                //delay 1 sec
                setTimeout(() => {
                  if (this.$refs.header != null) {
                    this.$refs.header.onClear();
                  }
                  this.processLoadingData();
                  this.blnAltStatus = false;
                }, 1000);
                // this.$refs.table.checkstateofdelete(true);
              } else if (res.data.candelete == false) {
                this.blnOpenform = false;
                this.fetchGetCampusbyId(this.campusId);

                this.timerCountHover = 0;
                this.timerEnabled = true;
                this.hover = false;
                this.blnPopupConfirmDelete = false;
                // reset PIN หลังจากDelete
                this.$refs.AlertPopup.password = null;

                //ถ้าลบไปแล้ว กดลบซ้ำ
                if (
                  res.data.message ==
                  "Cannot delete ,Please check mapping Id in interface"
                ) {
                  //ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้
                  this.blnnotdelete = true;
                  setTimeout(() => {
                    this.blnnotdelete = false;
                    this.processLoadingData();
                  }, 5000);
                }
                //กรณีเปลี่ยนเป็นMain แล้วกดลบ
                else if (
                  res.data.message == "This data is main campus,Cannot delete"
                ) {
                  //ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้
                  this.blnAltLoadingData = true;
                  setTimeout(() => {
                    this.blnAltLoadingData = false;
                    this.processLoadingData();
                  }, 5000);
                } else {
                  //ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้
                  this.blnAltLoadingData = true;
                  setTimeout(() => {
                    this.blnAltLoadingData = false;
                    this.processLoadingData();
                  }, 5000);
                }
              } else {
                this.blnAltStatusunsucess = true;
                this.timerCountHover = 0;
                this.timerEnabled = true;
                this.hover = false;
                this.blnPopupConfirmDelete = false;
                // reset PIN หลังจากDelete
                this.$refs.AlertPopup.password = null;
                setTimeout(() => {
                  this.blnAltStatusunsucess = false;
                }, 5000);
              }
              this.blnPopupConfirmDelete = false;

              // reset PIN หลังจากDelete
            } else {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              this.intWrongLogin = reslogin.WrongLogin;
              this.errorMsg = this.$t("357", {
                number: this.intPinCount - this.intWrongLogin,
              });
              this.blnError = true;
              if (this.$refs.AlertPopup != undefined) {
                this.$refs.AlertPopup.blnShowMSG = true;
              }
              if (this.intWrongLogin >= this.intPinCount) {
                VueCookies.remove("UserInfo", "/", cookiesPath);
              }
              // this.$refs.table.checkstateofdelete(false);
              // setTimeout(() => {
              //   this.processLoadingData();
              // }, 1000);
            }
            this.blnloadConfirmDelet = false;
          } else {
            this.blnloadConfirmDelet = false;
            this.errorMsg = this.$t("202", { fieldname: "(PIN)" });
            this.blnError = true;
            if (this.$refs.AlertPopup != undefined) {
              this.$refs.AlertPopup.blnShowMSG = true;
            }
          }
        }
      } catch (error) {
        if (this.$refs.AlertPopup != undefined) {
          this.$refs.AlertPopup.blnShowMSG = true;
        }
        this.blnloadConfirmDelet = false;
        // this.blnAltLoadingData = true;
        // this.timerCountHover = 0;
        // this.timerEnabled = true;
        // this.hover = false;
        console.log(error);
      }
    },

    // เปิด/ปิด Filter
    openFilter(showFilter) {
      this.blnOpenFilter = showFilter;
    },
    // blnopenConfigDisplay(showDisplay) {
    //   this.blnopenConfigDisplay = showDisplay;

    // },
    // ค้นหาข้อมูล
    SearchCampus(event) {
      this.strSearch = event;
      // เพิ่มข้อมูลไปยัง table เพื่อแสดงผล
      this.$refs.table.SearchListDataTable(event, this.strSearchField);
    },

    // เคลียร์ค่าการค้นหา
    clean() {
      this.strSearch = "";
      this.SearchCampus("");
      //this.filteredData(this.activeFiltersSelected);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-lg-fix {
  max-width: 910px;
  min-width: 400px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
}
.zoom-enter-active {
  animation: bounce-in 150ms;
}
.zoom-leave-active {
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
// .popup-container {
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   left: 0;
//   top: 0;
//   z-index: 9999;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   .overlay {
//     background-color: rgba(0, 0, 0, 0.263);
//     width: 100%;
//     height: 100%;
//     position: absolute;
//   }
// }
.move-to-top-level .layout:nth-child(odd) {
  order: 2;
  margin-bottom: 16px !important;
}
.form-fix {
  // max-width: max-content;
  width: 820px;
}
</style>
